import React, { useState, useEffect, useMemo } from 'react'
import Img, { GatsbyImageFluidProps, FluidObject } from 'gatsby-image'

// Style
import { Card } from './style'

// Props
import { ImageRandonFadeProps, ImgDataProps } from './types'

const ImageRandonFade = ({ data, json }: ImageRandonFadeProps) => {
  const images = useMemo(() => json.map((item: ImgDataProps) => data[item.image]), [ data ])
  const [ state, setState ] = useState({
    renderImg: images.slice(0, 6),
    previous: 0,
  })

  const changeImage = () => {
    let imgGet
    do {
      imgGet = Math.floor(Math.random() * 14)
    } while (state.renderImg.find((item: GatsbyImageFluidProps) => {
      return (item.fluid as FluidObject).src === images[imgGet].fluid.src
    }))

    let position

    do {
      position = Math.floor(Math.random() * 6)
    } while (position === state.previous)

    setState({
      renderImg: state.renderImg.map((item: GatsbyImageFluidProps, index: number) => {
        if (index === position) {
          return images[imgGet]
        }
        return item
      }),
      previous: position,
    })
  }

  useEffect(() => {
    setTimeout(changeImage, 1000)
  }, [ state ])

  return (
    <Card>
      {
        state.renderImg.map((item: GatsbyImageFluidProps) => {
          return (
            <div key={(item.fluid as FluidObject).src} className='image'>
              <Img fluid={item.fluid} className='fade-in' alt='Pessoa integrante do time de ux' />
            </div>
          )
        })
      }
    </Card>
  )
}

export default ImageRandonFade
