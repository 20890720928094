import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import BackgroundImage from 'gatsby-background-image'
import { grayscale, orange } from '../../styles/colors'

export const Hero = styled(BackgroundImage)`
  min-height: 650px;
  position: relative;
  display: flex;
  align-items: center;
  position: relative;

  &::before, &::after {
    width: 100%;
    background-size: cover !important;
    background-color: ${grayscale[500]};
    background-position: right !important;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: calc(100vh - 184px);
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 100vh;
  }

  .button {
    min-width: 100%;

    @media (min-width: ${breakpoints.lg}) {
      min-width: 358px;
    }
  }

  .btn-scroll {
    width: 60%;
    bottom: 40px;
    left: 20%;
    right: 20%;
    position: absolute;

    @media (min-width: ${breakpoints.lg}) {
      bottom: 20px;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        z-index: -5;
      }
    }
  }
`

export const BgSection = styled(BackgroundImage)`
  min-height: inherit;
  background-color: #161616;
  overflow: hidden;
  min-height: 580px;
  
  @media (min-width: ${breakpoints.md}) {
    min-height: 480px;
  }

  &.align-left {
    &::before, &::after {
      max-width: 837px;
      background-size: 100% !important;
      background-position: left top !important;

      @media (min-width: ${breakpoints.sm}) {
        left: -210px !important;
        background-position: left center !important;
      }
      @media (min-width: ${breakpoints.md}) {
        left: -210px !important;
      }
      @media (min-width: ${breakpoints.ld}) {
        left: -115px !important;
      }
      @media (min-width: ${breakpoints.xl}) {
        left: 0px !important;
      }
    }
  }

  &.align-right {
    &::before, &::after {
      max-width: 837px;
      background-size: 100% !important;
      background-position: right top !important;
      left: auto !important;

      @media (min-width: ${breakpoints.sm}) {
        background-position: right center !important;
        right: -210px !important;
      }
      @media (min-width: ${breakpoints.md}) {
        right: -210px !important;
      }
      @media (min-width: ${breakpoints.lg}) {
        right: -115px !important;
      }
      @media (min-width: ${breakpoints.xl}) {
        right: 0px !important;
      }
    }
  }
`
export const CarouselSection = styled.section`
  background-color: #161616;

  .carousel-container {
    max-width: 950px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap-reverse;

    @media (min-width: ${breakpoints.md}) {
      justify-content: center;
      flex-wrap: nowrap;
    }

    &-text {
      width: 312px;
      height: 304px;
      background: #212121;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content : center;
      padding-left: 24px;
      padding-right: 0;
      margin-top: 17px;

      @media (min-width: ${breakpoints.md}) {
        margin-right: 24px;
        width: 336px;
        height: 280px;
        margin-top: auto;
      }

      @media (min-width: ${breakpoints.lg}) {
        padding-right: 74px;
        width: 455px;
        height: 454px;
      }

      h4 {
        max-width: 357px;
        margin-bottom: 16px;
      }
    }

    &-image {
      width: 100%;
      width: 312px;
      height: 191px;
      
      .gatsby-image-wrapper {
        width: 312px;
        height: 191px;
        border-radius: 16px;
      }

      @media (min-width: ${breakpoints.md}) {
        width: 390px;
        height: 280px;

        .gatsby-image-wrapper {
          width: 336px;
          height: 280px;
          border-radius: 16px;
        }
      }

      @media (min-width: ${breakpoints.lg}) {
        width: 514px;
        height: 454px;

        .gatsby-image-wrapper {
          width: 455px;
          height: 454px;
          border-radius: 16px;
        }
      }
    }
  }
`
export const DarckSection = styled.section`
  background-color: #161616;

  @media (min-width: ${breakpoints.lg}) {
    min-height: 650px;
  }

  .text {
    max-width: 456px;

    &.align-right {
      @media (min-width: ${breakpoints.sm}) {
        margin-left: auto;
      }
    }
  }

  .image-devices {
    max-width: 552px;
    
    @media (min-width: ${breakpoints.sm}) {
      margin-left: auto;
    }
  }

  .button {
    min-width: 100%;

    @media (min-width: ${breakpoints.lg}) {
      min-width: 358px;
    }
  }
`
export const BoxCard = styled.div`
  max-width: 549px;
  margin-left: auto;

  .bg-dark {
    border: 0.5px solid #6A6C72 !important;
    border-radius: 16px;
    background: #212121 !important;
  }

  .title {
    margin-bottom: 33.5px
  }

  @media(min-width: ${breakpoints.md}) {
    &.bg-dark {
      min-height: 190px;

      &.mude-pro-nter {
        min-height: 205px;
      }     
    }

    .bg-dark {
      min-height: 200px;

      &.voce-por-dentro {
        min-height: 140px;
      }
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    .bg-white {
      min-height: 230px;
    }
  }

  @media(min-width: ${breakpoints.xl}) {
    .bg-white {
      min-height: 280px;   
    }
  } 
`
export const VemSerSangueLaranjaSection = styled(BackgroundImage)`
  min-height: 475px;

  @media(min-width: ${breakpoints.sm}) {
    min-height: 518px;
  }

  &::before, &::after {
    width: 100%;
    background-size: 100 100 !important;
    background-color: #161616;
  }


  .text {
    max-width: 456px;
  }

  .card {
    max-width: 454px;
    min-height: 200px;
    border-radius: 16px;
    padding: 40px 24px 40px 42px;
    background: #212121 !important;
    border: 0.5px solid #6A6C72;
    margin-left: auto;

    @media (min-width: ${breakpoints.lg}) {
      min-height: 235px;
      padding: 40px 24px 40px 70px;
    }

    &__icon-user {
      height: 44px;
      width: 44px;
      border-radius: 44px;
      background-color: ${orange.extra};
    }
  }
`
