import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      backgroundHero: imageSharp(fluid: {originalName: { regex: "/background-hero/" }}) {
        fluid(maxWidth: 1439, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundVagas: imageSharp(fluid: {originalName: { regex: "/background-vagas/" }}) {
        fluid(maxWidth: 1439, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      designCoreMobile: imageSharp(fluid: {originalName: { regex: "/design-core-mobile/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      designCore: imageSharp(fluid: {originalName: { regex: "/design-core-desktop/" }}) {
        fluid(maxWidth: 837, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      designOps: imageSharp(fluid: {originalName: { regex: "/design-ops-desktop/" }}) {
        fluid(maxWidth: 837, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      designOpsMobile: imageSharp(fluid: {originalName: { regex: "/design-ops-mobile/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      devices: imageSharp(fluid: {originalName: { regex: "/devices/" }}) {
        fluid(maxWidth: 552, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      designSistem: imageSharp(fluid: {originalName: { regex: "/design-system/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      uxWriting: imageSharp(fluid: {originalName: { regex: "/ux-write/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      uxResearch: imageSharp(fluid: {originalName: { regex: "/ux-research/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      uxMetrics: imageSharp(fluid: {originalName: { regex: "/ux-metrics/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter01: imageSharp(fluid: {originalName: { regex: "/equipe-inter-01/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter02: imageSharp(fluid: {originalName: { regex: "/equipe-inter-02/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter03: imageSharp(fluid: {originalName: { regex: "/equipe-inter-03/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter04: imageSharp(fluid: {originalName: { regex: "/equipe-inter-04/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter05: imageSharp(fluid: {originalName: { regex: "/equipe-inter-05/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter06: imageSharp(fluid: {originalName: { regex: "/equipe-inter-06/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter07: imageSharp(fluid: {originalName: { regex: "/equipe-inter-07/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter08: imageSharp(fluid: {originalName: { regex: "/equipe-inter-08/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter09: imageSharp(fluid: {originalName: { regex: "/equipe-inter-09/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter10: imageSharp(fluid: {originalName: { regex: "/equipe-inter-10/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter11: imageSharp(fluid: {originalName: { regex: "/equipe-inter-11/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter12: imageSharp(fluid: {originalName: { regex: "/equipe-inter-12/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter13: imageSharp(fluid: {originalName: { regex: "/equipe-inter-13/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      equipeInter14: imageSharp(fluid: {originalName: { regex: "/equipe-inter-14/" }}) {
        fluid(maxWidth: 185, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
