import styled from 'styled-components'
import { orange, white } from './colors'

export const OrangeButton = styled.button`
  background-color: ${orange.base};
  height: 45px;
  min-width: 310px;
  border-radius: 8px;
  color: ${white};
  font-size: 14px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:hover {
    color: ${white};
  }
`
