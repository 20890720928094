import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'

export const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
  height: auto;
  
  @media(min-width: ${breakpoints.xl}) {
    width: 552px;
    height: 520px;
  }

  .image {
    width: 33%;
    height: 145px;

    background-color: #FFDAB7;

    &:nth-child(2n + 1) {
      background-color: #FF7A00;
    }
    
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    @media(min-width: ${breakpoints.xl}) {
      width: 184px;
      height: 260px;
    }
  }
  
  @keyframes fadeIn { from { opacity:0; } to { opacity:1; }}

  .fade-in {
    opacity:0;
    animation:fadeIn ease-in;
    animation-fill-mode:forwards;
    animation-duration:0.2s;            
  }
`
