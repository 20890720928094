import React from 'react'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

// components
import Layout from '../../components/Layout'
import DefaultCarousel from '../../components/Carousels/DefaultCarousel'
import Icon from '../../components/Icon'
import ImageRandonFade from '../../components/ImageRandonFade'
import ScrollTo from '../../components/ScrollTo'

// Data
import AreasJSON from './assets/data/areas.json'
import youInsideTheMarketJSON from './assets/data/youInsideTheMarket.json'
import Team from './assets/data/team.json'

// Assets
import InstagramIcon from '../../assets/images/icons/icon-instagram.svg'
import MediumIcon from '../../assets/images/icons/icon-medium.svg'

// Types
import { youInsideTheMarketProps } from './types'

// PageContext
import pageContext from '../pageContext.json'

// Style
import { Hero, BgSection, CarouselSection, DarckSection, BoxCard, VemSerSangueLaranjaSection } from './style'
import { OrangeButton } from '../../styles/button'

// Page Query
import useContentQuery from './contentQuery'

interface IArea {
  image: string;
  title: string;
  description: string;
}

const ProductDesign: React.FC = () => {
  const data = useContentQuery()
  const icons = { InstagramIcon, MediumIcon }
  return (
    <Layout pageContext={pageContext}>
      <Hero
        className='d-flex py-5'
        Tag='section'
        id='hero'
        fluid={[ data.backgroundHero.fluid ]}
      >
        <div className='container'>
          <div className='row flex align-items-end'>
            <div className='col-12 col-md-10 col-lg-8'>
              <label className='d-none d-sm-block mb-5 mb-lg-4 f-14 lh-17 fw-400'>
                <a href='/' title='Carreiras' rel='noreferrer' className='text-gray-100'>
                  Carreiras
                </a><span className='text-gray-400'> ▸ Product Design Inter</span>
              </label>
              <h2 className='fs-72 fs-lg-96 lh-80 lh-lg-104 fw-600 text-white mb-4 ml-md-n1 ml-lg-n2'>
                Product Design <br /> Inter
              </h2>
              <p className='fs-18 lh-22 fw-400 text-white d-none d-sm-block mb-4'>
                Somos o time de design de produto do Inter. Atuamos com UX/ UI Design, criando produtos que simplificam o dia a dia
                de milhões de pessoas.
              </p>
              <p className='fs-18 lh-22 fw-400 text-white d-block d-sm-none mb-4'>
                Somos o time de design do Inter.
              </p>
              <OrangeButton
                className='button mb-4 mb-md-0' as='a' target='_blank' rel='noreferrer'
                href='https://bancointer.csod.com/ux/ats/careersite/16/home?c=bancointer'
              >
                Faça parte do time
              </OrangeButton>
            </div>
          </div>
          <div className='btn-scroll text-center'>
            <ScrollTo to='#design-system' title='Conheça mais sobre o Inter Design' styles='text-orange-base fs-16 fs-md-18 lh-22 fw-400'>
              Conheça mais sobre o Product Design Inter
              <Icon width='24' height='24' color='orange-base mt-2' icon='navigation/chevron-down' directory='v2' />
            </ScrollTo>
          </div>
        </div>
      </Hero>

      <BgSection
        className='d-flex align-items-end align-items-md-center py-5 align-left'
        Tag='section'
        id='about'
        fluid={[ data.designCoreMobile.fluid,
          {
            ...data.designCore.fluid,
            media: '(min-width: 576px)',
          },
        ]}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-6' />
            <div className='col-12 col-sm-6'>
              <h2 className='fs-32 lh-40 fs-lg-40 lh-lg-50 text-white fw-600'>É Design Core</h2>
              <p className='fs-18 lh-22 fw-400 text-white'>
                Nossos product designers transformam os objetivos do negócio e o propósito do Inter em produtos tangíveis e experiências
                que fazem a diferença na vida dos nossos clientes.
              </p>
            </div>
          </div>
        </div>
      </BgSection>

      <BgSection
        className='d-flex align-items-end align-items-md-center py-5 align-right'
        Tag='section'
        fluid={[
          data.designOpsMobile.fluid,
          {
            ...data.designOps.fluid,
            media: '(min-width: 576px)',
          },
        ]}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <h2 className='fs-32 lh-40 fs-lg-40 lh-lg-50 text-white fw-600'>É Design Ops</h2>
              <p className='fs-18 lh-22 fw-400 text-white'>
                Temos um time de Ops dedicado, com uma visão macro dos projetos, que garante a consistência de tudo que produzimos, atuando
                nas frentes de Design System, UX Writing, UX Metrics e UX Research.
              </p>
            </div>
            <div className='col-12 col-sm-6' />
          </div>
        </div>
      </BgSection>

      <CarouselSection id='design-system' className='py-5'>
        <div className='container'>
          <div className='carousel-container'>
            <DefaultCarousel
              containerClass='areas-clarouse mx-n4 mx-md-0 pl-4 pl-lg-2 pl-xl-3'
              sm={{ items: 1, partialVisibilityGutter: 5 }}
              md={{ items: 1, partialVisibilityGutter: 0 }}
              lg={{ items: 1, partialVisibilityGutter: 5 }}
              xl={{ items: 1 }}
            >
              {
                AreasJSON.map((area: IArea) => (
                  <div className='card' key={area.title}>
                    <div className='card-text'>
                      <h4 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-white my-3'>{area.title}</h4>
                      <p className='fs-18 lh-22 text-white fw-400 d-inline-block mb-0'>{area.description}</p>
                    </div>
                    <div className='card-image'>
                      <Img fluid={data[area.image].fluid} alt={area.title} title={area.title} />
                    </div>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </CarouselSection>

      <DarckSection className='py-5'>
        <div className='container'>
          <div className='row flex align-items-center'>
            <div className='col-12 col-sm-6 order-2 order-sm-1'>
              <div className='text align-left'>
                <h2 className='fs-24 fs-lg-40 lh-30 lh-lg-54 fw-600 text-white mb-4'>
                  Aceitamos desafios e jogamos juntos
                </h2>
                <p className='fs-18 lh-22 fw-400 text-white d-none d-sm-block mb-4'>
                  Construímos, dentre os nossos produtos, toda a experiência de uma plataforma que reúne solucões relevantes para os nossos clientes:
                  banking, shopping, seguros, investimentos e muito mais.
                </p>
              </div>
            </div>
            <div className='col-12 col-sm-6 order-1'>
              <div className='image-devices'>
                <Img fluid={data.devices.fluid} alt='Smartphones com aplicativos do Inter' title='devices' />
              </div>
            </div>
          </div>
        </div>
      </DarckSection>

      <DarckSection className='d-flex align-items-end align-items-md-center py-5'>
        <div className='container'>
          <div className='row d-flex align-items-end align-items-md-center'>
            <div className='col-12 col-sm-6'>
              <ImageRandonFade data={data} json={Team} />
            </div>
            <div className='col-12 col-sm-6'>
              <div className='text align-right mt-4 mt-sm-0'>
                <h2 className='fs-24 fs-lg-40 lh-30 lh-lg-54 fw-600 text-white mb-4'>
                  Em um time que enxerga o futuro que quer construir
                </h2>
                <p className='fs-18 lh-22 fw-400 text-white mb-4'>
                  Acreditamos em um futuro cada vez mais diverso e colaborativo e queremos construí-lo agora. Seguimos na nossa jornada
                  de descoberta, conhecimento e inovação.
                </p>
                <OrangeButton
                  className='button mb-4' as='a' target='_blank' rel='noreferrer'
                  href='https://bancointer.csod.com/ux/ats/careersite/16/home?c=bancointer'
                >
                  Faça parte do time
                </OrangeButton>
              </div>
            </div>
          </div>
        </div>
      </DarckSection>

      <DarckSection className='d-flex align-items-end align-items-md-center py-5'>
        <div className='container'>
          <div className='row flex align-items-center'>
            <div className='col-12 col-sm-6'>
              <div className='text align-left'>
                <h2 className='fs-24 fs-lg-40 lh-30 lh-lg-54 fw-600 text-white mb-4'>
                  Acompanhe as nossas novidades
                </h2>
                <p className='fs-18 lh-22 fw-400 text-white d-none d-sm-block mb-4'>
                  Fique por dentro de tudo que acontece no time de Product Design do Inter.
                </p>
              </div>
            </div>
            <div className='col-12 col-sm-6'>
              {
                youInsideTheMarketJSON.map((item: youInsideTheMarketProps, index: number) => (
                  <BoxCard className='pt-3 pb-2' key={index}>
                    <div className='d-flex flex-column pl-4 pr-3 pt-4 pb-2 py-md-4 pl-lg-3 bg-dark border rounded-16 justify-content-center voce-por-dentro'>
                      <div className='row mx-0'>
                        <div className='col-12 col-md-5 col-lg-6 align-self-end px-lg-0'>
                          <div className='d-flex title'>
                            <img src={icons[item.icon]} alt={item.title} className='mb-3 mr-3' />
                            <span className='text-white fs-16 lh-20 mb-3 fw-600'>{item.title}</span>
                          </div>
                          <h3 className='fs-16 lh-18 pb-1 fw-600 text-white description'>
                            {item.description}
                          </h3>
                        </div>
                        <div className='col-12 col-md-7 col-lg-6 mt-2 align-self-end px-lg-0'>
                          <Link
                            to={item.url} title='Veja nossos relatórios'
                            rel='noreferrer'
                            className='fs-14 lh-17 fw-400 text-orange-base float-right'
                          >
                            {item.buttonText}
                            <Icon width='27.12' height='24' color='orange-base ml-2' icon='navigation/arrow-right' directory='v2' />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </BoxCard>
                ))
              }
            </div>
          </div>
        </div>
      </DarckSection>

      <VemSerSangueLaranjaSection
        id='vem-ser-sangue-laranja'
        className='pb-4 pt-md-4 d-flex align-items-center'
        Tag='section'
        fluid={[ data.backgroundVagas.fluid ]}
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-sm-6'>
              <div className='text'>
                <h3 className='fs-24 fs-lg-40 text-white mb-3 mb-lg-4'>Vem ser Inter<br /> Product Design</h3>
                <p className='fs-14 fs-md-16 fs-lg-18 text-white'>
                  Se o que te move é fazer a diferença e trabalhar em um time que joga junto, então vem simplificar com a gente.
                </p>
              </div>
            </div>
            <a href='https://bancointer.csod.com/ux/ats/careersite/16/home?c=bancointer' target='_blank' rel='noreferrer' className='col-12 col-sm-6'>
              <div className='card d-flex flex-column justify-content-between bg-white'>
                <span className='card__icon-user d-flex justify-content-center align-items-center'>
                  <Icon color='orange' directory='v2' icon='products/banco/pessoa-fisica' width={24} height={24} />
                </span>
                <div className='d-flex align-items-end justify-content-between'>
                  <h4 className='fs-20 fs-lg-24 fw-600 lh-25 lh-lg-30 text-white'>Veja nossas <br />Oportunidades</h4>
                  <Icon color='orange-base' directory='v2' icon='navigation/arrow-right' width={24} height={24} />
                </div>
              </div>
            </a>
          </div>
        </div>
      </VemSerSangueLaranjaSection>
    </Layout>
  )
}

export default ProductDesign
